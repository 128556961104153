import axios from 'axios';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BASE_URL } from '../../Environment';

function Example(props) {
  const [mobileNumber, setMobile] = useState('')
  const [show, setShow] = useState(false);

  const handleClose = async () => {
    const id = localStorage.getItem('collectDataId')
    await axios.post(`${BASE_URL}/collect-data/edit-mobile/${id}`,{ mobileNumber })
      .then((res) => {
        console.log(res);
        localStorage.setItem('mobileNumber',mobileNumber);
        setShow(false);
        props.data(mobileNumber);
      })
      .catch((res)=>{
        console.log(res.response);
      })
  }
  const handleShow = () => setShow(true);


  return (
    <>
      <Button variant="primary" onClick={handleShow} style={{ backgroundColor: 'transparent', borderTop: 'none', borderLeft: 'none', borderRight: 'none', borderBottom: '1px solid #000', color: '#000', borderRadius: 0, display: 'table', margin: ' 0 0 0 auto' }}>
        Edit
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><h5>Edit mobile number</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Edit your mobile number" onChange={(e) => setMobile(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Example;