import React from 'react'
import { Pie } from 'react-chartjs-2';
import { Chart, Tooltip, Title, ArcElement, Legend } from 'chart.js';

Chart.register(
  Tooltip, Title, ArcElement, Legend
);

function ToolRight(props) {
  const total = props.data*props.time
  const interest = total - props.amount;
  const data = {
    datasets: [{
      data: [total,interest ],
      backgroundColor: [
        '#0d6efd',
        'yellow',
      ],
    }],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: [
      'Total Amount Payble',
      'Total Interest Payble'
    ],
    options: {
      plugins: {
        datalabels: {
          display: true,

        },

      },
    },
  };

  return (
    <div className='pie_chart'>
      <Pie data={data} />

    </div>
  )
}
export default ToolRight
