import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiper } from 'swiper/react';
import { ToastContainer, toast } from 'react-toastify';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import OtpTimer from 'otp-timer'
// import 'swiper/css/scrollbar';
import Modal1 from "../components/home/Modal";
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import axios from 'axios';
import { BASE_URL } from '../Environment';
import { useDispatch } from 'react-redux';
import { LOGIN } from '../Redux/UserSlice';
import OTPTimer from './OTPTimer';


const MultiForm = () => {
    const [counter, setCounter] = useState(0);
    const [loanName, setLoanName] = useState(get('loan'));
    const [location, setLocation] = useState(get('location'));
    const [loanType, setLoanType] = useState(get('loanType'));
    const [pincode, setPincode] = useState(get('pincode'));
    const [mobileNumber, setMobileNumber] = useState(get('mobileNumber'));
    const [firstName, setFirstName] = useState(get('firstName'));
    const [lastName, setLastName] = useState(get('lastName'));
    const [email, setEmail] = useState(get('email'));
    const [otp, setOtp] = useState("");
    const [check, setcheck] = useState(false);
    const [wsCheck, setWsCheck] = useState(false);

    useEffect(() => {
        localStorage.clear();
        setLoanName('');
        setLocation('');
        setLoanType('');
        setPincode('');
        setMobileNumber('');
        setFirstName('');
        setLastName('');
        setEmail('');
    }, [])


    // const dispatch = useDispatch()
    function store(key, value) {
        return localStorage.setItem(key, value);
    }
    function get(key) {
        return localStorage.getItem(key);
    }
    function remove(key) {
        return localStorage.removeItem(key);
    }

    


    // useEffect(() => {
    //     const collect = localStorage.getItem('collectDataId')
    //     if (collect) {
    //         document.getElementById('seven').style.display = 'block';
    //     } else if (!loanName) {
    //         document.getElementById('first').style.display = 'block';
    //     } else if (!location) {
    //         document.getElementById('second').style.display = 'block';
    //     } else if (!loanType) {
    //         document.getElementById('third').style.display = 'block';
    //     } else if (!pincode) {
    //         document.getElementById('four').style.display = 'block';
    //     } else if (!mobileNumber) {
    //         document.getElementById('fifth').style.display = 'block';
    //     } else if (!firstName && !lastName && !email) {
    //         document.getElementById('six').style.display = 'block';
    //     } else {
    //         document.getElementById('first').style.display = 'block';
    //     }

    // }, []);

    const firstSubmit = () => {
        if (loanName) {
            store('loan', loanName);
            setCounter(1);
        } else {
            toast.error('Please select best loan for you !', { autoClose: 1500 });
        }

    }
    const secondSubmit = () => {
        if (location) {
            store('location', location);
            setCounter(2);
        } else {
            toast.error('Please select your city!', { autoClose: 1500 });
        }
    }
    const thirdSubmit = () => {
        if (loanType) {
            store('loanType', loanType);
            setCounter(3);
        } else {
            toast.error('Please select loan type!', { autoClose: 1500 });
        }
    }
    const fourSubmit = () => {
        if (pincode) {
            store('pincode', pincode);
            setCounter(4);
        } else {
            toast.error('Please enter your pincode!', { autoClose: 1500 });
        }
    }

    const fifthSubmit = () => {
        if (mobileNumber) {
            store('mobileNumber', mobileNumber);
            setCounter(5);
        } else {
            toast.error('Please enter your mobile number!', { autoClose: 1500 });
        }
    }

    // submit user details
    const sixSubmit = async (e) => {
        e.preventDefault()
        if (firstName && lastName && email) {
            store('firstName', firstName);
            store('lastName', lastName);
            store('email', email);
            setCounter(6);
            console.log(loanName, location, loanType, pincode, mobileNumber, firstName, lastName, email);

            // await axios.post(`${BASE_URL}/register`, {
            //     loanName, location,
            //     loanType, pincode, mobileNumber, firstName, lastName, email
            // })
            //     .then((res) => {
            //         document.getElementById('six').style.display = 'none';
            //         document.getElementById('seven').style.display = 'block';
            //         localStorage.setItem("collectDataId", res.data.data);
            //     })
            //     .catch((res) => {

            //     })
        } else {
            toast.error('Please enter your personal details!', { autoClose: 1500 });
        }
    }
    const reset=()=>{
        window.location.reload();
    }

    // //submit otp and generate token
    // const submitOtp = async () => {
    //     const id = localStorage.getItem('collectDataId')
    //     await axios.post(`${BASE_URL}/otp/${id}`, { otp })
    //         .then((res) => {
    //             remove('loanType');
    //             remove('lastName');
    //             remove('location');
    //             remove('email');
    //             remove('loan');
    //             remove('mobileNumber');
    //             remove('pincode');
    //             remove('firstName');
    //             store('autToken', res.data.access_token);
    //             dispatch(LOGIN({
    //                 userToken: localStorage.getItem('autToken'),
    //                 success: true
    //             }))
    //             toast.success("Thank You For Register!");
    //         })
    //         .catch((res) => {
    //             toast.error(res.response.data.message);
    //         })
    // }

    // //resend otp
    // const resend = async (e) => {
    //     setShowTimer(true)
    //     const collect = localStorage.getItem('collectDataId')
    //     await axios.get(`${BASE_URL}/resend/${collect}`)
    //         .then((res) => {
    //             toast.success(res.data.msg);
    //         })
    //         .catch((res) => {

    //         })
    // }

    // //for ccoutnt-Down
    // const [showTimer, setShowTimer] = useState(true);

    // const handleTimerComplete = () => {
    //     setShowTimer(false)
    // Handle OTP timer completion logic here
    // };


    if (counter == 0) {
        return (
            <div className="loan_tab_main_personal">
                <div className="tab-content" style={{ height: "100vh" }}>
                    <div className="full_tabpan" id='first'>
                        <div className="text_div">
                            <div className="head">
                                <p>Find the best loan for you</p>
                            </div>
                            <div className="form_content">
                                <div className="tab_1_form">
                                    <form>
                                        <div className="form-check">
                                            <label className={`form-check-label ui_radio ${loanName === 'Salaried' ? 'active' : ''}`} htmlFor="flexRadioDefault1">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_1_img/Salaried.jpg" alt="" />
                                                </div>
                                                <h5 className="input_name">Salaried</h5>
                                                {/* <p>Run own business</p> */}
                                                <input className="form-check-input" type="radio" value="Salaried" name="loanName" id="flexRadioDefault1" onChange={(e) => setLoanName(e.target.value)} />
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className={`form-check-label ui_radio ${loanName === 'Salaried Professional' ? 'active' : ''}`} htmlFor="flexRadioDefault2">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_1_img/Salaried-Professional.jpg" alt="" />
                                                </div>
                                                <h5 className="input_name">Salaried Professional</h5>
                                                <input className="form-check-input" type="radio" name="loanName" value="Salaried Professional" id="flexRadioDefault2" onChange={(e) => setLoanName(e.target.value)} />
                                            </label>

                                        </div>
                                        <div className="form-check">
                                            <label className={`form-check-label ui_radio ${loanName === 'Self Employed Business' ? 'active' : ''}`} htmlFor="flexRadioDefault3">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_1_img/Self-Employed-Business.jpg" alt="" />
                                                </div>
                                                <h5 className="input_name">Self Employed Business</h5>
                                                <input className="form-check-input" type="radio" value="Self Employed Business" name="loanName" id="flexRadioDefault3" onChange={(e) => setLoanName(e.target.value)} />
                                            </label>

                                        </div>
                                        <div className="form-check">
                                            <label className={`form-check-label ui_radio ${loanName === 'Self Employed Professional' ? 'active' : ''}`} htmlFor="flexRadioDefault4">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_1_img/Self-Employed-Professional.jpg" alt="" />
                                                </div>
                                                <h5 className="input_name">Self Employed Professional</h5>
                                                {/* <p>(e.g. driver, electrician, farmer, shopkeeper, etc.)</p> */}
                                                <input className="form-check-input" type="radio" value='Self Employed Professional' name="loanName" id="flexRadioDefault4" onChange={(e) => setLoanName(e.target.value)} />
                                            </label>

                                        </div>
                                        <div className="form-check">
                                            <label className={`form-check-label ui_radio ${loanName === 'Independent Worker' ? 'active' : ''}`} htmlFor="flexRadioDefault5">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_1_img/Independent-Worker.jpg" alt="" />
                                                </div>
                                                <h5 className="input_name">Independent Worker</h5>
                                                <input className="form-check-input" type="radio" value="Independent Worker" name="loanName" id="flexRadioDefault5" onChange={(e) => setLoanName(e.target.value)} />
                                            </label>

                                        </div>
                                        <div className="form-check">
                                            <label className={`form-check-label ui_radio ${loanName === 'Student' ? 'active' : ''}`} htmlFor="flexRadioDefault6">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_1_img/Students.jpg" alt="" />
                                                </div>
                                                <h5 className="input_name">Student</h5>
                                                {/* <p>Ear regularly monthly income</p> */}
                                                <input className="form-check-input" type="radio" value="Student" name="loanName" id="flexRadioDefault6" onChange={(e) => setLoanName(e.target.value)} />
                                            </label>

                                        </div>
                                        <div className="form-check">
                                            <label className={`form-check-label ui_radio ${loanName === 'Retired' ? 'active' : ''}`} htmlFor="flexRadioDefault7">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_1_img/Retired.jpg" alt="" />
                                                </div>
                                                <h5 className="input_name">Retired</h5>
                                                {/* <p>Own a practice(e.g. Doctor, C.A, etc.)</p> */}
                                                <input className="form-check-input" type="radio" value="Retired" name="loanName" id="flexRadioDefault7" onChange={(e) => setLoanName(e.target.value)} />
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className={`form-check-label ui_radio ${loanName === 'Homemaker' ? 'active' : ''}`} htmlFor="flexRadioDefault8">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_1_img/Homemaker.jpg" alt="" />
                                                </div>
                                                <h5 className="input_name">Homemaker</h5>
                                                {/* <p>Own a practice(e.g. Doctor, C.A, etc.)</p> */}
                                                <input className="form-check-input" type="radio" value="Homemaker" name="loanName" id="flexRadioDefault8" onChange={(e) => setLoanName(e.target.value)} />
                                            </label>
                                        </div>
                                    </form>
                                </div>
                                <div className="continue">
                                    <button className='btn btn-danger' onClick={firstSubmit} >
                                        Continue
                                    </button>
                                    {/* <ToastContainer /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        )
    }
    else if (counter == 1) {
        return (
            <div className="loan_tab_main_personal">
                <div className="tab-content" style={{ height: "100vh" }}>
                    <div className="full_tabpan" id='second' >
                        <div className="text_div">
                            <div className="head">
                                <p>Find the best loan for you</p>
                            </div>
                            <div className="form_content">
                                <div className="tab_2_form">
                                    <h6>Select city to check your eligibility</h6>
                                    <form>
                                        <div className="form-check">
                                            <label className={`form-check-label city_check_label ${location === 'Chennai' ? 'active' : ''}`} htmlFor="flexRadioDefault1">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_2_img/Chennai.png" alt="" />
                                                </div>
                                                <h5 className="city">Chennai</h5>
                                                <input className="form-check-input" type="radio" value="Chennai" name="flexRadioDefault" id="flexRadioDefault1" onChange={(e) => setLocation(e.target.value)} />
                                            </label>

                                        </div>
                                        <div className="form-check">
                                            <label className={`form-check-label city_check_label ${location === 'Mumbai' ? 'active' : ''}`} htmlFor="flexRadioDefault2">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_2_img/Mumbai.png" alt="" />
                                                </div>
                                                <h5 className="city">Mumbai</h5>
                                                <input className="form-check-input" type="radio" value="Mumbai" name="flexRadioDefault" id="flexRadioDefault2" onChange={(e) => setLocation(e.target.value)} />
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className={`form-check-label city_check_label ${location === 'New Delhi' ? 'active' : ''}`} htmlFor="flexRadioDefault2">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_2_img/New-Delhi.png" alt="" />
                                                </div>
                                                <h5 className="city">New Delhi</h5>
                                                <input className="form-check-input" type="radio" value="New Delhi" name="flexRadioDefault" id="flexRadioDefault2" onChange={(e) => setLocation(e.target.value)} />
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className={`form-check-label city_check_label ${location === 'Bangalore' ? 'active' : ''}`} htmlFor="flexRadioDefault2">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_2_img/Bangalore.png" alt="" />
                                                </div>
                                                <h5 className="city">Bangalore</h5>
                                                <input className="form-check-input" type="radio" value="Bangalore" name="flexRadioDefault" id="flexRadioDefault2" onChange={(e) => setLocation(e.target.value)} />
                                            </label>

                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label city_check_label hover_none" htmlFor="flexRadioDefault2">
                                                <div className="img_div">
                                                    <img src="assets/images/tab_2_img/city.png" alt="" />
                                                </div>
                                                <h5 className="city">Other</h5>
                                                <p>Enter the city name</p>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" style={{ backgroundColor: 'transparent' }} placeholder="Enter the city" onChange={(e) => setLocation(e.target.value)} />
                                            </label>

                                        </div>
                                    </form>
                                </div>
                                <div className="continue mt-4">
                                    <button className='btn btn-danger' onClick={secondSubmit}  >
                                        Continue
                                    </button>
                                    <ToastContainer />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }
    else if (counter == 2) {
        return (
            <div className="loan_tab_main_personal">
                <div className="tab-content" style={{ height: "100vh" }}>
                    <div className="full_tabpan" id='third' >
                        <div className="text_div">
                            <div className="head">
                                <p>Find the best loan for you</p>
                            </div>
                            <div className="form_content">
                                <div className="_tab_to_tab">
                                    <div className="plan_tabdiv">
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                                    aria-selected="true">Investment</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                                                    aria-selected="false">Loans</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-contact1-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-contact1" type="button" role="tab" aria-controls="pills-contact1"
                                                    aria-selected="false">Cards</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                                aria-labelledby="pills-home-tab">

                                                <div className="_tab_to_form_pills">
                                                    <form>
                                                        <div className="form-check">
                                                            <label className={`form-check-label ui_radio ${loanType === 'Savings Account' ? 'active' : ''}`} htmlFor="flexRadioDefault1">
                                                                <div className="img_div">
                                                                    <img src="assets/images/tab_3_img/Savings-Account.png" alt="" />
                                                                </div>
                                                                <h5 className="input_name">Savings Account</h5>
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="Savings Account" id="flexRadioDefault1" onChange={(e) => setLoanType(e.target.value)} />
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <label className={`form-check-label ui_radio ${loanType === 'Silver Rate' ? 'active' : ''}`} htmlFor="flexRadioDefault2">
                                                                <div className="img_div">
                                                                    <img src="assets/images/tab_3_img/Silver-Rate.png" alt="" />
                                                                </div>
                                                                <h5 className="input_name">Silver Rate</h5>
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="Silver Rate" id="flexRadioDefault2" onChange={(e) => setLoanType(e.target.value)} />
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <label className={`form-check-label ui_radio ${loanType === 'Fixed Deposit' ? 'active' : ''}`} htmlFor="flexRadioDefault3">
                                                                <div className="img_div">
                                                                    <img src="assets/images/tab_3_img/Fixed-Deposit.png" alt="" />
                                                                </div>
                                                                <h5 className="input_name">Fixed Deposit</h5>
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="Fixed Deposit" id="flexRadioDefault3" onChange={(e) => setLoanType(e.target.value)} />
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <label className={`form-check-label ui_radio ${loanType === 'Mutual Funds' ? 'active' : ''}`} htmlFor="flexRadioDefault4">
                                                                <div className="img_div">
                                                                    <img src="assets/images/tab_3_img/Mutual-Funds.png" alt="" />
                                                                </div>
                                                                <h5 className="input_name">Mutual Funds</h5>
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="Mutual Funds" id="flexRadioDefault4" onChange={(e) => setLoanType(e.target.value)} />
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <label className={`form-check-label ui_radio ${loanType === 'Best RD Interest Rates' ? 'active' : ''}`} htmlFor="flexRadioDefault5">
                                                                <div className="img_div">
                                                                    <img src="assets/images/tab_3_img/Best-RD-Interest-Rates.png" alt="" />
                                                                </div>
                                                                <h5 className="input_name">Best RD Interest Rates</h5>
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="Best RD Interest Rates" id="flexRadioDefault5" onChange={(e) => setLoanType(e.target.value)} />
                                                            </label>

                                                        </div>
                                                        <div className="form-check">
                                                            <label className={`form-check-label ui_radio ${loanType === 'Gold Rate' ? 'active' : ''}`} htmlFor="flexRadioDefault6">
                                                                <div className="img_div">
                                                                    <img src="assets/images/tab_3_img/Gold-Rate.png" alt="" />
                                                                </div>
                                                                <h5 className="input_name">Gold Rate</h5>
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="Gold Rate" id="flexRadioDefault6" onChange={(e) => setLoanType(e.target.value)} />
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <label className={`form-check-label ui_radio ${loanType === 'National Pension Scheme' ? 'active' : ''}`} htmlFor="flexRadioDefault7">
                                                                <div className="img_div">
                                                                    <img src="assets/images/tab_3_img/National-Pension-Scheme.png" alt="" />
                                                                </div>
                                                                <h5 className="input_name">National Pension Scheme</h5>
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" value="National Pension Scheme" id="flexRadioDefault7" onChange={(e) => setLoanType(e.target.value)} />
                                                            </label>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                            <div className="tab-pane fade" id="pills-contact" role="tabpanel"
                                                aria-labelledby="pills-contact-tab">
                                                bbbbbbbbbbbbbb Under these types of personal loan schemes, you don’t need to provide any
                                                security or collateral to the bank. If you meet all the eligibility criteria and have a
                                                decent credit score, then you can avail yourself of an unsecured personal loan.

                                            </div>
                                            <div className="tab-pane fade" id="pills-contact1" role="tabpanel"
                                                aria-labelledby="pills-contact1-tab">
                                                bbbbbbbbbbbbbb Under these types of personal loan schemes, you don’t need to provide any
                                                security or collateral to the bank. If you meet all the eligibility criteria and have a
                                                decent credit score, then you can avail yourself of an unsecured personal loan.

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="continue">
                                    <button className='btn btn-danger' onClick={thirdSubmit}  >
                                        Continue
                                    </button>
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (counter == 3) {
        return (
            <div className="loan_tab_main_personal">
                <div className="tab-content" style={{ height: "100vh" }}>
                    <div className="full_tabpan" id='four' >
                        <div className="text_div">
                            <div className="head">
                                <p>Find the best loan for you</p>
                            </div>
                            <div className="form_content">
                                <div className="input_form_tab">
                                    <h6>Enter your current residential pincode</h6>
                                    <form>
                                        <div className="mb-3 mt-2" style={{ position: 'relative' }}>
                                            <input type="number" value={pincode} className="form-control" id="exampleFormControlInput1" style={{ paddingLeft: '50px' }} placeholder="Enter your pincode" onChange={(e) => setPincode(e.target.value)} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000" className="bi bi-building-check" viewBox="0 0 16 16">
                                                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514Z" />
                                                <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6.5a.5.5 0 0 1-1 0V1H3v14h3v-2.5a.5.5 0 0 1 .5-.5H8v4H3a1 1 0 0 1-1-1V1Z" />
                                                <path d="M4.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" />
                                            </svg>
                                        </div>
                                    </form>
                                </div>
                                <div className="continue">
                                    <button className='btn btn-danger' onClick={fourSubmit}  >
                                        Continue
                                    </button>
                                    <ToastContainer />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (counter == 4) {
        return (
            <div className="loan_tab_main_personal">
                <div className="tab-content" style={{ height: "100vh" }}>
                    <div className="full_tabpan" id='fifth' >
                        <div className="text_div">
                            <div className="head">
                                <p>Find the best loan for you</p>
                            </div>
                            <div className="form_content">
                                <div className="input_form_tab">
                                    <h6>Provide your mobile number</h6>
                                    <form>
                                        <div className="mb-3 mt-2" style={{ position: 'relative' }}>
                                            <input type="tel" className="form-control" value={mobileNumber} id="exampleFormControlInput1" placeholder="My Mobile (Required)" style={{ paddingLeft: '50px' }} onChange={(e) => setMobileNumber(e.target.value)} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000" className="bi bi-phone" viewBox="0 0 16 16">
                                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                            </svg>
                                        </div>
                                        <div className="form-check ps-0 d-flex">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault10" style={{ border: '1px solid #000', backgroundColor: ' #000' }} onChange={(e) => setcheck(e.target.checked)} />
                                            <label className="form-check-label ps-2" htmlFor="flexCheckDefault" style={{ fontSize: '13px' }}>
                                                I authorise Lorem ispum to call/SMS/email me about its products & have accepted the terms of the <strong>Privacy Policy.</strong> More+
                                            </label>
                                        </div>
                                    </form>
                                </div>
                                <div className="continue">
                                    <button className={`btn btn-danger ${check == true ? "" : "disabled"} `} onClick={fifthSubmit}  >
                                        Continue
                                    </button>
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (counter == 5) {
        return (
            <div className="loan_tab_main_personal">
                <div className="tab-content" style={{ height: "100vh" }}>
                    <div className="full_tabpan" id='six' >
                        <div className="text_div">
                            <div className="head">
                                <p>Find the best loan for you</p>
                            </div>
                            <div className="form_content">
                                <div className="input_form_tab input_form_tab_wp ">
                                    <h6>Enter your personal details</h6>
                                    <form>
                                        <div className="mb-3 mt-2" style={{ position: 'relative' }}>
                                            <input type="text" className="form-control" value={firstName} id="exampleFormControlInput1" placeholder="My First Name" style={{ paddingLeft: '50px' }} onChange={(e) => setFirstName(e.target.value)} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000" className="bi bi-person-fill" viewBox="0 0 16 16">
                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                            </svg>
                                        </div>

                                        <div className="mb-3 mt-2" style={{ position: 'relative' }}>
                                            <input type="text" value={lastName} className="form-control" id="exampleFormControlInput1" placeholder="My Last Name" style={{ paddingLeft: '50px' }} onChange={(e) => setLastName(e.target.value)} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000" className="bi bi-person-fill" viewBox="0 0 16 16">
                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                            </svg>
                                        </div>

                                        <div className="mb-3 mt-2" style={{ position: 'relative' }}>
                                            <input type="email" value={email} className="form-control" id="exampleFormControlInput1" placeholder="My Email (Required)" style={{ paddingLeft: '50px' }} onChange={(e) => setEmail(e.target.value)} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                            </svg>
                                        </div>

                                        <h6 className="mb-2 mt-2"><small>We don't spam or sell your details to annoying people.</small></h6>

                                        <p className="mb-2 mt-2">By clicking on the "Next/Continue/Submit" button. You accept the following terms and conditions.</p>

                                        <p className="mb-2 mt-2">I hereby appoint BankBazaar as my authorised representative to receive my credit information from
                                            <strong>Experian</strong>
                                            <strong>
                                                <a href="/">
                                                    More+ I decline the above terms and conditions
                                                </a>
                                            </strong>
                                        </p>

                                        <div className="form-check" >
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault10" style={{ border: ' 1px solid #000', backgroundColor: ' #000' }} onClick={(e => setWsCheck(e.target.checked))} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault" style={{ fontSize: '13px', padding: 0, display: 'flex', alignItems: 'center' }}>
                                                Get updates via <img src="assets/images/tab_img/6.png" style={{ width: '20px' }} alt="" />Whatsapp
                                            </label>
                                        </div>
                                    </form>
                                </div>
                                <div className="continue">
                                    <button className={`btn btn-danger ${wsCheck == true ? "" : "disabled"}`} onClick={sixSubmit}  >
                                        Continue
                                    </button>
                                    <ToastContainer />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (counter == 6) {
        return (
            <div className="loan_tab_main_personal">
                <div className="tab-content" style={{ height: "100vh" }}>
                    <div className="full_tabpan" id='eight'>
                        <div className="text_div">
                            <div className="head">
                                <p>Find the best loan for you</p>
                            </div>
                            <div className="form_content">
                                <div className="input_form_tab">
                                    <h2 className="text-center">Thank You for Submitting!</h2>
                                    <h6 className="my-3">we will get back to you soon</h6>
                                    <div className="thankyou-svg">
                                        <img src="assets/images/thankyou.svg" alt="" />
                                    </div>
                                </div>
                                <div className="text-center">
                                <button className='btn btn-danger mb-3' onClick={reset}>
                                    Home
                                </button>
                                
                                <ToastContainer />
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }else{
        return (
            <div>
                <p>Submit</p>
            </div>
        )
    }






















    // <div className="full_tabpan" id='seven' >
    //     <div className="text_div">
    //         <div className="head">
    //             <p>Find the best loan for you</p>
    //         </div>
    //         <div className="form_content">
    //             <div className="input_form_tab">
    //                 <h6>Verify your mobile number!</h6>
    //                 <form>
    //                     <div className="mb-3 mt-2" style={{ position: 'relative' }}>
    //                         <input type="text" className="form-control" id="exampleFormControlInput1" value={mobileNumber} style={{ paddingLeft: '50px' }} />
    //                         <svg xmlns="http://www.w3.org/2000/svg" style={{ top: '16%' }} width="20" height="20" fill="#000" className="bi bi-phone" viewBox="0 0 16 16">
    //                             <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
    //                             <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
    //                         </svg>
    //                         <Modal1 data={setMobileNumber} />
    //                     </div>
    //                     <div className="mb-3 mt-2" style={{ position: 'relative' }}>
    //                         <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter OTP" style={{ paddingLeft: '50px' }} onChange={(e) => setOtp(e.target.value)} />
    //                         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#000" className="bi bi-lock-fill" viewBox="0 0 16 16">
    //                             <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
    //                         </svg>
    //                     </div>
    //                     {
    //                         showTimer==false ?<div className="anchor_div">
    //                         <a className='disable' style={{ cursor: "pointer" }} onClick={resend}>
    //                             <strong>Resend</strong>
    //                         </a>
    //                     </div>:""}
    //                 </form>
    //             </div>
    //             <div className="continue">
    //                 <button className='btn btn-danger mb-3' onClick={submitOtp}>
    //                     Continue
    //                 </button>
    //                 {showTimer && <OTPTimer durationInSeconds={120} onTimerComplete={handleTimerComplete} />}
    //                 <ToastContainer />
    //             </div>
    //         </div>

    //     </div>
    // </div>
}

export default MultiForm
