import Iframe from 'react-iframe'

function AboutCompany() {
  return (
    <div>
         {/* <!-- who we are section start --> */}
        <section>
            <div className="about_fulldiv">
                <div className="img_div">
                    <img src="assets/images/loan_about_us_img/2.png" alt="" />
                </div>
                <div className="about_detail">
                    <div className="short_text">
                        <p><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#c50808"
                                className="bi bi-dash-lg" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                    d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
                            </svg>&nbsp; WHO WE ARE</p>
                    </div>

                    <h2>About Our Company</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero veritatis fugiat ad quasi fuga
                        perspiciatis pariatur dignissimos nobis. Debitis, soluta?</p>

                    <div className="acc-container">
                        <div className="acc">
                            <div className="acc-head">
                                <p>Who We are</p>
                            </div>
                            <div className="acc-content">
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis aut sequi
                                    obcaecati ea libero
                                </p>
                            </div>
                        </div>

                        <div className="acc">
                            <div className="acc-head">
                                <p>How we works</p>
                            </div>
                            <div className="acc-content">
                                <p>
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                    Dolore magnam, nobis consequuntur nemo cupiditate vel sit
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- who we are section end --> */}


        {/* <!-- project detail start here --> */}
        <section>
            <div className="count_full_div">
                <div className="count_div">
                    <div className="icon_div">
                        <img src="assets/images/loan_about_us_img/3.png" alt="" />
                    </div>
                    <div className="text_div">
                        <span>18364+</span> <br />Loan Increase
                    </div>
                </div>

                <div className="count_div">
                    <div className="icon_div">
                        <img src="assets/images/loan_about_us_img/4.png" alt="" />
                    </div>
                    <div className="text_div">
                        <span>328K</span> <br />Project Done
                    </div>
                </div>


                <div className="count_div">
                    <div className="icon_div">
                        <img src="assets/images/loan_about_us_img/5.png" alt="" />
                    </div>
                    <div className="text_div">
                        <span>328K</span> <br /> Awards Won
                    </div>
                </div>


                <div className="count_div">
                    <div className="icon_div">
                        <img src="assets/images/loan_about_us_img/6.png" alt="" />
                    </div>
                    <div className="text_div">
                        <span>100%</span> <br /> Happy Clients
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- project detail end here --> */}


        {/* <!-- why people choose us start --> */}
        <section>
            <div className="choose_us_fulldiv">
                <div className="choose_us_detail">
                    <div className="div_part">
                        <h3>Why People Choose Us</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut commodi voluptatibus molestias
                            repudiandae quaerat cumque sint tenetur architecto, corporis sequi?</p>
                        <a href="/">Our Services</a>
                    </div>

                    <div className="div_part">
                        <div className="point_div">
                            <div className="point">
                                <div className="img_div">
                                    <img src="assets/images/loan_about_us_img/7.png" alt="" />
                                </div>
                                <div className="text_div">

                                    <span>
                                        Reliability
                                    </span>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. </p>
                                </div>
                            </div>
                            <div className="point">
                                <div className="img_div">
                                    <img src="assets/images/loan_about_us_img/7.png" alt="" />
                                </div>
                                <div className="text_div">
                                    <span>
                                        Guarantee
                                    </span>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. </p>
                                </div>
                            </div>

                            <div className="point">
                                <div className="img_div">
                                    <img src="assets/images/loan_about_us_img/7.png" alt="" />
                                </div>
                                <div className="text_div">
                                    <span>
                                        Experience
                                    </span>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. </p>
                                </div>
                            </div>

                            <div className="point">
                                <div className="img_div">
                                    <img src="assets/images/loan_about_us_img/7.png" alt="" />
                                </div>
                                <div className="text_div">
                                    <span>
                                        Speed
                                    </span>
                                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="video_part">
                    <div className="img_div">
                        <img src="assets/images/loan_about_us_img/10.png" alt="" />
                        <div className="play_icon">
                            <a href="/" className="popup-btn"><img src="assets/images/loan_about_us_img/9.png" alt="" /></a>
                        </div>
                    </div>
                    <div className="text_div">
                        <h4>Get Loan In 15 mins on your Bank Account</h4>
                        <p>Limited Period offer. <a href="/">Apply now</a></p>
                    </div>
                </div>
                <div className="video-popup">
                    <div className="popup-bg"></div>
                    <div className="popup-content">
                        {/* <!--         <p className="popup-title">Youtube</p> --> */}
                        <Iframe src="https://youtube.com/embed/gQojMIhELvM?autoplay=0" className="video"></Iframe>
                        <button className="close-btn">close</button>
                    </div>
                </div>

            </div>
        </section>
        {/* // <!-- why people choose us end --> */}
    </div>
  );
}

export default AboutCompany;
