import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./fullpages/HomePage";
import AboutPage from "./fullpages/AboutPage";
import ContactPage from "./fullpages/ContactPage";
import FaqPage from "./fullpages/FaqPage";
import PersonalLoan from "./fullpages/PersonalLoan";
import Footer from "./components/Footer";
import BlogPage from "./fullpages/BlogPage";
import MultiForm from "./pages/MultiForm";
import NotFound from "./components/NotFound";
import BlogDetail from "./fullpages/BlogDetail";
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN, selectUserToken } from "./Redux/UserSlice";
import LoanModal from "./components/personal-loan/LoanModal";

function App() {
  // const [token, setToken] = useState(localStorage.getItem('autToken'))
  const dispatch = useDispatch()
  const userToken = useSelector(selectUserToken)
  // useEffect(() => {
  //   const Authtoken = localStorage.getItem('autToken');
  //   if (Authtoken) {
  //     setToken(Authtoken);
  //     localStorage.removeItem('collectDataId');
  //   }
  // }, []);
  useEffect(() => {
    dispatch(LOGIN({
      userToken: localStorage.getItem('autToken'),
      success: true
    }))
  }, [])

  return (
    <div>

     
          <MultiForm /> 
          
      {/* {
        !userToken ?
          <MultiForm /> :
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route path="/loan" element={<PersonalLoan />} />
              <Route path="/blogs" element={<BlogPage />} />
              <Route path="/blog/:id" element={<BlogDetail />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
      } */}
    </div>
  );
}

export default App;
