// const { useSelector } = require("react-redux");
// const { selectUserToken } = require("./Redux/UserSlice");
// const { default: axios } = require("axios");

const { useSelector } = require("react-redux");
const { selectUserToken } = require("./Redux/UserSlice");

exports.BASE_URL = "http://127.0.0.1:8000/api";
exports.IMAGE_URL = "http://127.0.0.1:8000/images";

//get token value


// exports.Token = () => {
//     const userToken = useSelector(selectUserToken)
//     return userToken;
// }
exports.Expire = (res) => {
    if (res.response.status == 401) {
        localStorage.clear();
        window.location.reload();
    }
}


