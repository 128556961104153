import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function PartnerOffer() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1024 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 1024, min: 800 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 800, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div>
            <section>
                <div className="web_partner">
                <h3>Blockbuster Offers</h3>

                <div className="">
                    <Carousel responsive={responsive}
                    itemclassName="carousel-item-padding-15-px"
                    >
                        <div>
                            <div className="total_image_slider">
                                <img src="assets/images/loan_home_page_img/3.png" alt="" />
                                <div className="achor_div">
                                    <a href="/">
                                        Avail Now
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div>

                            <div className="total_image_slider">
                                <img src="assets/images/loan_home_page_img/4.png" alt="" />
                                <div className="achor_div">
                                    <a href="/">
                                        Avail Now
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="total_image_slider">
                                <img src="assets/images/loan_home_page_img/5.png" alt="" />
                                <div className="achor_div">
                                    <a href="/">
                                        Avail Now
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="total_image_slider">
                                <img src="assets/images/loan_home_page_img/6.png" alt="" />
                                <div className="achor_div">
                                    <a href="/">
                                        Avail Now
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="total_image_slider">
                                <img src="assets/images/loan_home_page_img/3.png" alt="" />
                                <div className="achor_div">
                                    <a href="/">
                                        Avail Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="total_image_slider">
                                <img src="assets/images/loan_home_page_img/4.png" alt="" />
                                <div className="achor_div">
                                    <a href="/">
                                        Avail Now
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="total_image_slider">
                                <img src="assets/images/loan_home_page_img/5.png" alt="" />
                                <div className="achor_div">
                                    <a href="/">
                                        Avail Now
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="total_image_slider">
                                <img src="assets/images/loan_home_page_img/6.png" alt="" />
                                <div className="achor_div">
                                    <a href="/">
                                        Avail Now
                                    </a>
                                </div>
                            </div>

                        </div>
                    </Carousel>
                </div>
                </div>
              

            </section>
        </div>

    );
}

export default PartnerOffer;