
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name:"user",
    initialState: {
        userToken:null,
      },
      
    reducers:{
       
        LOGIN:(state,action)=>{
            
             state.userToken = action.payload.userToken
            
        },
        LOGOUT:(state,action)=>{
            
            state.userToken=null
        },
    }
})

export const {LOGIN,LOGOUT,IMAGE,NAME} = userSlice.actions

export const selectUserToken=(state)=>state.user.userToken;


export default userSlice.reducer;